import React, { useEffect, useState } from "react"

import { buildFeature } from "../middleware/buildFeature"
import "./ObjectInfo.scss"
import Checkbox from "../Checkbox/Checkbox"
import punycode from "punycode"
import { useTranslation } from "react-i18next"

function getConditionBySubscription(
  conditions,
  selectedSubscription,
  dictionary,
  noSubscriptionTitle
) {
  if (!selectedSubscription || !conditions[selectedSubscription]) {
    return noSubscriptionTitle + dictionary.subscription[selectedSubscription]
  }
  let key = null,
    text = null,
    subscription
  for (subscription in dictionary.subscription) {
    key = subscription.substr(0, 1)
    text = dictionary.subscription[subscription]
    if (!conditions[subscription]) {
      continue
    }
    conditions[subscription] = conditions[subscription].replace(
      new RegExp(`/[${key}]/g`),
      "[" + text + "]"
    )
  }
  return conditions[selectedSubscription].replace(/\n/g, "<br>")
}

function ObjectInfo({ close, supplier, subscription, loadedFilters }) {
  const { t } = useTranslation()
  const [selectedSubscription, setSelectedSubscription] = useState(subscription)
  const [currentSupplier, setCurrentSupplier] = useState({
    address: "",
    phone: ["+375 (29) 399-17-66"],
    info: "",
    rules: "",
    name: "",
    gallery: "",
    activities: [],
    tags: [],
    condition_list: {},
    supplierSubscriptions: [],
  })
  useEffect(() => {
    if (subscription) {
      setSelectedSubscription(subscription)
    }
  }, [subscription])

  useEffect(() => {
    if (supplier) {
      const updatedFeature = buildFeature(
        supplier,
        selectedSubscription,
        loadedFilters.dictionary
      )
      const supplierSubscriptionsAll = updatedFeature.properties.attraction_objects.flatMap(({subscriptions}) => subscriptions);
      const supplierSubscriptionArray = [...new Set(supplierSubscriptionsAll)];
      setCurrentSupplier({
        address: updatedFeature.properties.address,
        phone: updatedFeature.properties.phone,
        info: updatedFeature.properties.info,
        rules: updatedFeature.properties.rules,
        name: updatedFeature.properties.name,
        gallery: updatedFeature.properties.gallery,
        activities: updatedFeature.properties.activities,
        tags: updatedFeature.properties.tags,
        condition_list: updatedFeature.properties.condition_list,
        supplierSubscriptions: updatedFeature.properties.attraction_objects ? supplierSubscriptionArray : []
      })
    }
  }, [supplier, selectedSubscription, loadedFilters])

  const handleClickOverlay = (event) => {
    event.stopPropagation()
    event.preventDefault()
    return close()
  }
  const handleClick = (event) => {
    event.stopPropagation()
  }
  const handleKeyUpOverlay = (handler) => {
    return function onKeyUp(event) {
      if (["keyup"].includes(event.type) && ["Escape"].includes(event.key)) {
        return handler()
      }
    }
  }

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "")

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/)

    if (match && match[1] === "375") {
      return (
        "+" +
        match[1] +
        " (" +
        match[2] +
        ") " +
        match[3] +
        "-" +
        match[4] +
        "-" +
        match[5]
      )
    }

    return str
  }

  const formatUrl = (str) => {
    if (!str) {
      return ""
    }
    const part = ("" + str).replace(/https?:\/\//, "")
    if (part.indexOf("xn-") !== -1) {
      try {
        return punycode.toUnicode(part)
      } catch (e) {
        console.error(e)
      }
    }
    return part
  }

  const noSubscriptionTitle = t("sportObjectsSearch.item.no_subscription")

  return (
    <section
      role="button"
      tabIndex={0}
      className="sport-object-object-info"
      onClick={handleClickOverlay}
      onKeyUp={handleKeyUpOverlay(handleClickOverlay)}
    >
      <section
        role="button"
        tabIndex={"0"}
        onClick={handleClick}
        onKeyUp={handleKeyUpOverlay(handleClick)}
        className="content"
      >
        <button className="close" onClick={close} aria-label="close">
          x
        </button>
        <h1>{currentSupplier.name}</h1>
        <div className="rules">
          <div className="title">
            {t("sportObjectsSearch.item.title")}
            {loadedFilters.subscription
              .filter((item) => {
               if(currentSupplier.supplierSubscriptions.indexOf(item.key) >-1){
                 return item.key && currentSupplier.condition_list[item.key]
               }
              })
              .map((item) => {
                return (
                  <label
                    className="cardtype"
                    key={item.key}
                    htmlFor={"sub_checkbox_" + item.key}
                  >
                    <Checkbox
                      id={"sub_checkbox_" + item.key}
                      name="object_cards"
                      item={item}
                      checked={item.key === selectedSubscription}
                      onAdd={setSelectedSubscription}
                      onDelete={() => {}}
                      type="radio"
                    />
                  </label>
                )
              })}
          </div>
          <span
            key="ad"
            dangerouslySetInnerHTML={{
              __html: getConditionBySubscription(
                currentSupplier.condition_list,
                selectedSubscription,
                loadedFilters.dictionary,
                noSubscriptionTitle
              ),
            }}
          />
          <br />
          {currentSupplier.rules && (
            <a
              href={currentSupplier.rules}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("sportObjectsSearch.item.ask_read_rule")}
            </a>
          )}
          {!currentSupplier.rules && (
            <strong>{t("sportObjectsSearch.item.ask_read_rule")}</strong>
          )}
        </div>

        <div className="about">
          <div className="title">{t("sportObjectsSearch.item.about")}</div>
          <span
            key="gi"
            dangerouslySetInnerHTML={{ __html: currentSupplier.info }}
          />
        </div>

        <div className="activities">
          <div className="title">{t("sportObjectsSearch.item.activities")}</div>
          {currentSupplier.activities.map((activity, key) => {
            return (
              <span className="tag" key={key}>
                {activity}
              </span>
            )
          })}
        </div>
        <div className="tags">
          <div className="title">{t("sportObjectsSearch.item.tags")}</div>
          {currentSupplier.tags.map((tag, key) => {
            return tag && (
              <span className="tag" key={key}>
                {tag}
              </span>
            )
          })}
        </div>
        <div className="contacts">
          <div className="title">{t("sportObjectsSearch.item.contact")}</div>
          <div>
            {t("sportObjectsSearch.item.address")}:{" "}
            <a
              href={
                "https://www.google.com/maps/search/?api=1&query=" +
                supplier.coords
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              {currentSupplier.address}
            </a>
          </div>
          <div>
            {t("sportObjectsSearch.item.phone")}:{" "}
            {currentSupplier.phone.map((number, index) => {
              return (
                <a
                  key={number + index}
                  href={"tel:" + number}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatPhoneNumber(number)}
                </a>
              )
            })}
          </div>
          <div>
            {t("sportObjectsSearch.item.website")}:{" "}
            <a href={supplier.url} target="_blank" rel="noopener noreferrer">
              {formatUrl(supplier.url)}
            </a>
          </div>
        </div>
        <div className="schedule">
          <div
            className="title"
            title={t("sportObjectsSearch.item.schedule_reminder")}
          >
            {t("sportObjectsSearch.item.schedule")}
          </div>
          <div key="mon">{supplier.mon}</div>
          <div key="tue">{supplier.tue}</div>
          <div key="wed">{supplier.wed}</div>
          <div key="thu">{supplier.thu}</div>
          <div key="fri">{supplier.fri}</div>
          <div key="sat">{supplier.sat}</div>
          <div key="sun">{supplier.sun}</div>
          {supplier.sh_url && supplier.sh_url !== "false" ? (
            <a
              href={supplier.sh_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t("sportObjectsSearch.item.schedule_url")}
            </a>
          ) : (
            ""
          )}
          <div className="word-break-all">
            {supplier.sh_message ? supplier.sh_message : ""}
          </div>
          <div className="small">
            * {t("sportObjectsSearch.item.schedule_reminder")}
          </div>
          {supplier.last_changed_at ? (
            <span className="small">
              * {t("sportObjectsSearch.item.updated")}{" "}
              {new Date(supplier.last_changed_at).toLocaleDateString()}
            </span>
          ) : (
            ""
          )}
        </div>
        {currentSupplier.gallery && (
          <img
            className="gallery"
            src={currentSupplier.gallery}
            alt={t("sportObjectsSearch.item.photo") + currentSupplier.name}
          />
        )}
      </section>
    </section>
  )
}

export default ObjectInfo
