export function buildFeature(supplier, subscription, dictionary) {
  const objects = supplier.attraction_objects
  const allTags =dictionary.tag
  const keyOfActualTags = supplier.tags

  const translatedTags = []
  keyOfActualTags.map((key) => {
      if(key){
        translatedTags.push(allTags[key])
      }
  })

  supplier.tags = translatedTags

  let activity,
    object,
    activities = {},
    firstActivity = "",
    list,
    copayment_suffix = "",
    i = "",
    j = ""

  for (i in objects) {
    object = objects[i]
    // TODO: remove first condition when we add condition_list
    if (object.subscriptions && !object.subscriptions.includes(subscription)) {
      continue
    }
    list = object.list
    copayment_suffix = ""
    if (object.has_copayment) {
      copayment_suffix =
        " С доплатой " + Number(object.copayment) / 100 + " BYN"
    }
    if (!firstActivity && list[0]) {
      firstActivity = dictionary.activity[list[0]] + copayment_suffix
    }
    if (list.length) {
      for (j in list) {
        activity = list[j]
        activities[dictionary.activity[activity] + copayment_suffix] = 1
      }
    }
  }
  supplier.activities = Object.getOwnPropertyNames(activities)
  supplier.firstActivity = firstActivity

  supplier.coords = supplier.lat + "," + supplier.lng
  supplier.hintContent = supplier.name
  return {
    type: "Feature",
    id: supplier.id,
    geometry: {
      type: "Point",
      coordinates: [Number(supplier.lat), Number(supplier.lng)],
    },
    properties: supplier,
  }
}
