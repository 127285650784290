import React, { useRef } from "react"
import classNames from "classnames"

import "./CityRegionSelect.scss"
import Checkbox from "../Checkbox/Checkbox"
import { useOutsideCatch } from "../../../hooks/useOutsideCatch"
import Button from "../../../common/Button/Button"
import { useTranslation } from "react-i18next"

function CityRegionSelect({
  values,
  subscription,
  options,
  color,
  placeholder,
  onUpdate,
  onSetMapPosition,
  isCityMenuOpen,
  setCityMenuOpen,
  defaults
}) {
  const { t } = useTranslation()
  function chooseCity(key) {
    onSetMapPosition(key)
    setCityMenuOpen(false)
  }
  function handleUpdate() {
    setCityMenuOpen(false)
  }
  function handleCleanup(event) {
    event.preventDefault()
    event.stopPropagation()
    return onUpdate([])
  }
  function keyDownA11y(handler) {
    return function onKeyDown(event) {
      if (
        ['keydown', 'keypress'].includes(event.type)
        && ['Enter', ' '].includes(event.key)
      ) {
        handler();
      }
    }
  }
  function closeList() {
    setCityMenuOpen(false)
  }
  function renderOptions() {
    return (
      <div className="options">
        <div
          className="back"
          onClick={closeList}
          onKeyDown={() => keyDownA11y(closeList)}
          aria-hidden="true"
        >
          {t('cityRegionSelect.open_filters')}
          <button className="close" onClick={() => setCityMenuOpen(false)} aria-label="close" />
        </div>
        {options
          .filter(item => !(item.key === defaults.city && subscription === "region"))
          .map((item,i) => {
            return (
              <label key={item.key} htmlFor={`item${i}`}>
                <Checkbox
                  item={item}
                  checked={values.indexOf(item.key) !== -1}
                  onAdd={() => chooseCity(item.key)}
                  onDelete={() => {}}
                  type="radio"
                />
              </label>
            )
          })}
        <Button color="accept" onClick={handleUpdate}>
          {t('cityRegionSelect.accept')}
        </Button>
      </div>
    )
  }
  const wrapperRef = useRef(null)
  useOutsideCatch(wrapperRef, () => {
    setCityMenuOpen(false)
  })
  function handleOpenMenu(event) {
    event.preventDefault()
    event.stopPropagation()
    setCityMenuOpen(!isCityMenuOpen)
  }
  return (
    <div
      ref={wrapperRef}
      className={classNames("city-region-select", color, {
        "menu-open": isCityMenuOpen,
      })}
    >
      <button
        onClick={handleOpenMenu}
        className={classNames("", "", {
          empty: 1,
        })}
      >
        {placeholder}
        <span className="cleanup" onClick={handleCleanup}
        onChange={()=>{}} aria-hidden="true">
          {t('cityRegionSelect.reset')}
        </span>
      </button>

      {isCityMenuOpen && renderOptions()}
    </div>
  )
}

export default CityRegionSelect
