import React, { useEffect, useRef, useState, useCallback } from "react"
import Select from "./Select/Select"
import CityRegionSelect from "./CityRegionSelect/CityRegionSelect"
import InputSearch from "./InputSearch/InputSearch"
import { Coordinator } from "./Coordinator"
import "./SportObjectsSearch.scss"
import SupplierDetails from "../../supplierDetails/supplierDetails.js";
import ObjectInfo from "./ObjectInfo/ObjectInfo.js"
import { graphql, useStaticQuery } from "gatsby"
import { shuffle, isEmpty, set } from "lodash"
import { globalHistory, useLocation } from "@reach/router"

import classNames from "classnames"
import filtersByCountry from "../../../utils/filters2.json"
import Button from "../../common/Button/Button"
import { locationSearchToParams } from "./locationSearchToParams"

import { useTranslation } from "react-i18next"
import loadable from "@loadable/component"
const MapComponent = loadable(() => import("./MapComponent/MapComponent"))

const MAP_STATE_CENTER_STORAGE = (country) =>
  "suppliersv2_map_state_center" + country
const MAP_STATE_CITY_LOCATION_STORAGE = (city) =>
  "suppliersv2_map_city_center_" + city
const MAP_STATE_CITY_ZOOM_STORAGE = (country) =>
  "suppliersv2_map_city_zoom_" + country
const MAP_STATE_SUBSCRIPTION_STORAGE = (country) =>
  "suppliersv2_map_state_subscription_" + country
const MAP_STATE_TAG_STORAGE = "suppliersv2_map_state_tag"
const MAP_STATE_ACTIVITY_STORAGE = "suppliersv2_map_state_activity"

function SportObjectsSearch({ titleRu, country }) {
  const { t } = useTranslation()
  const language = country
  const { pathname } = useLocation();
  const modalRef = useRef()
  const parentListRef = useRef()
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          serverPath
        }
      }
    }
  `)
  const doLoadMapFilters = useCallback((filters) =>  modalRef.current.doLoadMapFilters(filters),
    [],
  )
  const doLoadSupplier = useCallback((supplier) =>  modalRef.current.doLoadSupplier(supplier),
    [],
  )
  const configurableFilters = filtersByCountry.countries[country]
 
  
  const defaultFilters = {
    subscription: configurableFilters.defaults.subscription,
    city: configurableFilters.defaults.city,
    location: filtersByCountry.location[configurableFilters.defaults.city],
    zoom: filtersByCountry.zoom[configurableFilters.defaults.city],
  }
  const emptyMapFilters = {
    subscription: [],
    city: [],
    tag: [],
    activity: [],
    zoom: defaultFilters.zoom,
    location: defaultFilters.location,
    defaults: {
      subscription: defaultFilters.subscription,
      city: defaultFilters.city,
    },
    dictionary: {
      activity: {},
      tag: {},
      subscription: {},
      city: {},
    },
  }

  const translate = (name, value) =>
  loadedFilters.dictionary[name]
    ? loadedFilters.dictionary[name][value]
    : null;
  const fixCenterCauseYandex = ({ lat, lng }) => {
    const lngFix = 0.00006812
    const latFix = 0.00014216
    try {
      return { lat: lat - latFix, lng: lng - lngFix }
    } catch (e) {
      console.warn(e)
      return { lat, lng }
    }
  }

  const [activeSupplier, setActiveSupplier] = useState(null)
  const isBrowser = () => typeof window !== "undefined"
  const setupMinimumEmptyFilters = () => {
    const getStorageCity = () => {
      const storageCity = isBrowser()
        ? localStorage.getItem(MAP_STATE_CENTER_STORAGE(country))
        : defaultFilters.city
      if (!storageCity) {
        return defaultFilters.city
      }
      return String(storageCity).toLowerCase()
    }
    const makeMinimumFilter = () => {
      let subscription = isBrowser()
        ? localStorage.getItem(MAP_STATE_SUBSCRIPTION_STORAGE(country))
        : defaultFilters.subscription
      if (!subscription) {
        subscription = defaultFilters.subscription
      }
      const city = getStorageCity()

      return {
        subscription: String(subscription).toLowerCase(),
        city,
        country,
        language,
        tag: [],
        activity: [],
      }
    }
    const minimum = makeMinimumFilter()

    const makeEmptyFilter = () => {
      const tags = isBrowser()
        ? localStorage.getItem(MAP_STATE_TAG_STORAGE)
        : ""
      const tagsArray = String(tags)
        .split(":")
        .filter((value) => !!value)
      const storedActivitiesAsStr = isBrowser()
        ? localStorage.getItem(MAP_STATE_ACTIVITY_STORAGE)
        : ""
      const activitiesArray = String(storedActivitiesAsStr)
        .split(":")
        .filter((value) => !!value)

      return Object.assign(
        {
          tag: tagsArray,
          activity: activitiesArray,
        },
        minimum
      )
    }
    const selected = makeEmptyFilter()
    return { minimumFilter: minimum, emptyFilter: selected }
  }
  const { minimumFilter, emptyFilter } = setupMinimumEmptyFilters()
  const [loadedFilters, setLoadedFilters] = useState(emptyMapFilters)
  const [selectedFilter, setSelectedFilter] = useState(emptyFilter)
  const [mapCenterPointAndZoom, setMapCenterPointAndZoom] = useState(() => {
    const getStorageCityLocation = () => {
      try {
        const value = isBrowser()
          ? localStorage.getItem(
              MAP_STATE_CITY_LOCATION_STORAGE(emptyFilter.city)
            )
          : filtersByCountry.location[emptyFilter.city]
        if (!value) {
          return filtersByCountry.location[emptyFilter.city]
        }
        const latLngArr = String(value).split(":")
        return { lat: parseFloat(latLngArr[0]), lng: parseFloat(latLngArr[1]) }
      } catch (e) {
        return filtersByCountry.location[emptyFilter.city]
      }
    }
    const getStorageCityZoom = () => {
      const value = isBrowser()
        ? localStorage.getItem(MAP_STATE_CITY_ZOOM_STORAGE(country))
        : filtersByCountry.zoom[emptyFilter.city]
      if (!value) {
        return filtersByCountry.zoom[emptyFilter.city]
      }
      return value
    }
    const center = getStorageCityLocation()
    const zoom = getStorageCityZoom()
    return { center: fixCenterCauseYandex(center), zoom }
  })
   const [isMounted, setIsMounted] = useState(false)
   const [windowSize, setWindowSize] = useState({
    width: typeof window !== "undefined" ? window.outerWidth : 0,
    height: typeof window !== "undefined" ? window.outerHeight : 0,
  })
  const [searchableSuppliers, setSearchableSuppliers] = useState([])
  const [searchableSuppliersHash, setSearchableSuppliersHash] = useState({})
  const [loadedMarkers, setLoadedMarkers] = useState([])
  const [buttonClicked, setButtonClicked] = useState(false)
  const [isSearchOpen, setSearchOpen] = useState(false)
  const [isFilterOpen, setFilterOpen] = useState(false)
  const [isCityMenuOpen, setCityMenuOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [typingTimeout, setTypingTimeout] = useState(0)
  const [moveEndTimeout, setMoveEndTimeout] = useState(0)
  const [zoomEndTimeout, setZoomEndTimeout] = useState(0)

  const setStorageSubscription = (value) =>
    localStorage.setItem(MAP_STATE_SUBSCRIPTION_STORAGE(country), value)
  const setCityLocationCenter = (city, location, zoom) => {
    if (location && zoom) {
      localStorage.setItem(MAP_STATE_CENTER_STORAGE(country), city)
      localStorage.setItem(
        MAP_STATE_CITY_LOCATION_STORAGE(city),
        location.lat + ":" + location.lng
      )
      localStorage.setItem(MAP_STATE_CITY_ZOOM_STORAGE(country), zoom)
      setMapCenterPointAndZoom({
        center: fixCenterCauseYandex(location),
        zoom: zoom,
      })
    }
    return city
  }
  const setStorageTags = (values) =>
    localStorage.setItem(MAP_STATE_TAG_STORAGE, values.join(":"))
  const setStorageActivities = (values) =>
    localStorage.setItem(MAP_STATE_ACTIVITY_STORAGE, values.join(":"))
  const setTags = (input) => {
    setStorageTags(input)
    const changedFilter = changingFilterByName(selectedFilter, input, "tag")
    return loadingMapAndList(changedFilter, searchText)
  }
  useEffect(() => {
    if(!isMounted && modalRef.current) {
      setIsMounted(true)
    }
  }, [isMounted])
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  useEffect(() => {
    const removeListener = globalHistory.listen((locationWrapper) => {
      openModalIfHistory(locationWrapper.location)
    })
    return () => {
      removeListener()
    }
  })

  const openModalIfHistory = (location) => {
    if (location && location.search) {
      const supplier = locationSearchToParams(location.search)
      if (supplier.id) {
        return doLoadSupplier(supplier)
      }
      else if(supplier.supplier_id && !buttonClicked && !activeSupplier) {
        return doLoadSupplier({
          id: supplier.supplier_id,
          name: supplier.supplier_name
        })
      }
    } else {
      setActiveSupplier(null)
    }
  }

  const setActivities = (input) => {
    setStorageActivities(input)
    const changedFilter = changingFilterByName(
      selectedFilter,
      input,
      "activity"
    )
    return loadingMapAndList(changedFilter, searchText)
  }

  const changeSettingsSelectedFilter = (filter, values, name) => {
    return Object.assign({}, filter, { [name]: values })
  }

  function prepareMultiStringGraphQl(array) {
    /* eslint-disable */
    function addslashes(str) {
      return (str + "").replace(/[\\"']/g, "\\$&").replace(/\u0000/g, "\\0")
    }
    /* eslint-enable */
    return Array.isArray(array) && array.length
      ? '"' + array.map(addslashes).join('","') + '"'
      : ""
  }

  function updateMarkersBySearch(text, suppliers){
    const hash = {}
    if(text) {
      suppliers.forEach(supplier => hash[supplier.id] = 1)
    }
    setSearchableSuppliersHash(hash)
    updateMarkers(loadedMarkers, hash)
  }

  function updateMarkers(markers, hashMap) {
    setLoadedMarkers(markers.map(marker=>{
      const firstSupplier = marker.suppliers[0]
      firstSupplier.icon = hashMap[firstSupplier.id] ? 'yellow' : "blue"
      return marker
    }))
  }

  const doLoadMapMarkers = (filters, leftTopPoint, centerPoint) => {
    const query = `
        {
  map_markers(general:{language:"${filters.language}",city:"${
      filters.city
    }",country:"${filters.country}",subscription:"${filters.subscription}"
  ,activity:[${prepareMultiStringGraphQl(
    filters.activity
  )}],tag:[${prepareMultiStringGraphQl(filters.tag)}]},
  leftTopPoint: { lat: ${leftTopPoint.lat}, lng: ${leftTopPoint.lng}},
    centerPoint:{lat: ${centerPoint.lat}, lng: ${centerPoint.lng}}) {
    lat
    lng
    suppliers {
      id
      name
    }
  }
}
    `
    return fetch(data.site.siteMetadata.serverPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data && response.data.map_markers) {
          updateMarkers(response.data.map_markers, searchableSuppliersHash)
        }
      })
      .catch((e) => {
        console.error(e)
        updateMarkers([], searchableSuppliersHash)
      })
  }

  const doLoadMapSearch = (filters, text) => {
    const updatedText = text.replace(/"/g, '\\"')
    const query = `
        {
  map_search_suppliers(general:{language:"${filters.language}",city:"${
      filters.city
    }",country:"${filters.country}",
  subscription:"${filters.subscription}",activity:[${prepareMultiStringGraphQl(
      filters.activity
    )}],tag:[${prepareMultiStringGraphQl(filters.tag)}]},
   search:"${updatedText}") {
    id
    name
  }
}
    `
    return fetch(data.site.siteMetadata.serverPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data && response.data.map_search_suppliers) {
          setSearchableSuppliers(shuffle(response.data.map_search_suppliers))
          updateMarkersBySearch(updatedText, response.data.map_search_suppliers)
          return openModalIfHistory(window.location)
        }
      })
      .catch((e) => {
        console.error(e)
        setSearchableSuppliers([])
      })
  }

  const addHistoryStateSupplierId = (supplier) => {

    if(window && window.location && !window.location.search) {
      try {
        window.history.pushState(
          { supplier_id: supplier.id },
          `Объект ${supplier.name}`,
          `${pathname}?supplier_id=${supplier.id}&supplier_name=${supplier.name}`
        )
      } catch (e) {
        console.log(e)
      }
    }
  }
  function hideActiveSupplier() {
    setActiveSupplier(null)
    try {
      window.history.pushState(
        { supplier_id: null },
        `Объекты ${translate("city", selectedFilter.city)}, ${translate(
          "subscription",
          selectedFilter.subscription
        )}`,
        `${pathname}`
      )
    } catch (e) {
      console.log(e)
    }
  }
  function buttonSetActiveSupplier(supplier) {
    setButtonClicked(true)
    return doLoadSupplier(supplier).then(addHistoryStateSupplierId)
  }
  useEffect(() => {
    if (parentListRef.current && !buttonClicked) {
      const detailsRef = parentListRef.current.querySelector(
        ".sport-object-object-info"
      )
      if (detailsRef) {
        detailsRef.scrollIntoView({
          block: "start",
          behavior: "auto",
          inline: "start",
        })
      }
    }
    setButtonClicked(false)
  }, [activeSupplier, buttonClicked])

  function changeSearch(value) {
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }
    setSearchText(value)
    setTypingTimeout(
      setTimeout(function () {
        return doLoadMapSearch(selectedFilter, value)
      }, 500)
    )
  }

  function clearFilter() {
    setSelectedFilter(minimumFilter)
    setSearchText("")
    return loadingMapAndList(minimumFilter, searchText)
  }

  const loadingMapMarkersWithSize = (filter, centerPointAndZoom, size) => {
    const dev = new Coordinator(
      centerPointAndZoom.center.lat,
      centerPointAndZoom.center.lng,
      Number(centerPointAndZoom.zoom),
      size.x,
      size.y
    )
    const leftTopPoint = dev.getCoordinates(0, 0)
    return doLoadMapMarkers(filter, leftTopPoint, {
      lat: centerPointAndZoom.center.lat,
      lng: centerPointAndZoom.center.lng,
    })
  }

  const loadingMapMarkers = (filter, centerPointAndZoom) => {
    return loadingMapMarkersWithSize(filter, centerPointAndZoom, {
      x: windowSize.width,
      y: windowSize.height,
    })
  }

  const doLoadMapAndList = (filter, text, centerPointAndZoom) => {
    return doLoadMapSearch(filter, text).then(() =>
      loadingMapMarkers(filter, centerPointAndZoom)
    )
  }

  const loadingMapAndList = (filter, text) => {
    return doLoadMapSearch(filter, text).then(() =>
      loadingMapMarkers(filter, mapCenterPointAndZoom)
    )
  }

  function removeByValue(key, filterName) {
    if (filterName === "activity") {
      const changedFilter = changeSettingsSelectedFilter(
        selectedFilter,
        selectedFilter.activity.filter((item) => item !== key),
        "activity"
      )
      setStorageActivities(changedFilter.activity)
      setSelectedFilter(changedFilter)
      return loadingMapAndList(changedFilter, searchText)
    } else if (filterName === "tag") {
      const changedFilter = changeSettingsSelectedFilter(
        selectedFilter,
        selectedFilter.tag.filter((item) => item !== key),
        "tag"
      )
      setStorageTags(changedFilter.tag)
      setSelectedFilter(changedFilter)
      return loadingMapAndList(changedFilter, searchText)
    }
  }

  function setSubscriptionOpenCity(subscription) {
    if (subscription === selectedFilter.subscription) {
      return
    }

    setStorageSubscription(subscription)
    let changedFilter = changingFilterByName(
      selectedFilter,
      subscription,
      "subscription"
    )
    const city = loadedFilters.defaults.city
    if (subscription === "region") {
      changedFilter = changeSettingsSelectedFilter(changedFilter, city, "city")
      setSelectedFilter(changedFilter)
      return setCityMenuOpen(true)
    }
    if (subscription === "silver") {
      changedFilter = changingFilterByName(changedFilter, city, "city")
    }
    return doLoadMapFilters(changedFilter).then((loadedFilters) => {
      setCityLocationCenter(city, loadedFilters.location, loadedFilters.zoom)
      return doLoadMapAndList(changedFilter, searchText, {
        center: loadedFilters.location,
        zoom: loadedFilters.zoom,
      })
    })
  }

  function setSelectedCity(city) {
    if (selectedFilter.city === city) {
      return
    }
    const changedFilter = changingFilterByName(selectedFilter, city, "city")
    return doLoadMapFilters(changedFilter).then((loadedFilters) => {
      setCityLocationCenter(city, loadedFilters.location, loadedFilters.zoom)
      return doLoadMapAndList(changedFilter, searchText, {
        center: loadedFilters.location,
        zoom: loadedFilters.zoom,
      })
    })
  }

  function changingFilterByName(filter, values, name) {
    const preparedFilter = changeSettingsSelectedFilter(filter, values, name)

    setSelectedFilter(preparedFilter)
    return preparedFilter
  }

  function setMoveEnd(leafletEvent) {
    if (moveEndTimeout) {
      clearTimeout(moveEndTimeout)
    }
    const config = {
      center: leafletEvent.sourceTarget.getCenter(),
      zoom: leafletEvent.sourceTarget.getZoom(),
      size: leafletEvent.sourceTarget.getSize(),
    }
    const meters =
      Math.max(
        fixCenterCauseYandex(config.center).lat -
          mapCenterPointAndZoom.center.lat,
        fixCenterCauseYandex(config.center).lng -
          mapCenterPointAndZoom.center.lng
      ) * 111000
    if (Math.abs(meters) < 50) {
      return
    }
    clearTimeout(moveEndTimeout)
    setMoveEndTimeout(
      setTimeout(function () {
        setMapCenterPointAndZoom({
          center: config.center,
          zoom: config.zoom,
        })
        return loadingMapMarkersWithSize(selectedFilter, config, config.size)
      }, 500)
    )
  }

  function setZoomEnd(leafletEvent) {
    if (zoomEndTimeout) {
      clearTimeout(zoomEndTimeout)
    }
    const config = {
      center: leafletEvent.sourceTarget.getCenter(),
      zoom: leafletEvent.sourceTarget.getZoom(),
      size: leafletEvent.sourceTarget.getSize(),
    }
    clearTimeout(zoomEndTimeout)
    setZoomEndTimeout(
      setTimeout(function () {
        setMapCenterPointAndZoom({ center: config.center, zoom: config.zoom })
        return loadingMapMarkersWithSize(selectedFilter, config, config.size)
      }, 500)
    )
  }

  return (
    <section className="sport-objects-search fullscreen">
      {titleRu && <h1>{titleRu}</h1>}
      <div
        className={"dropdowns" + (isSearchOpen || isFilterOpen ? " hide" : "")}
      >
        <button className="search-dropdown" onClick={() => setSearchOpen(true)}>
          {t("sportObjectsSearch.inputMobile")}
        </button>
        <button className="filter-dropdown" onClick={() => setFilterOpen(true)}>
          {t("sportObjectsSearch.filterMobile")}
        </button>
      </div>
      { isMounted?
        <>
          <form
            className={isSearchOpen || isFilterOpen ? " show" : ""}
            onSubmit={(event) => event.preventDefault()}
          >
            <InputSearch
              className={"search" + (isSearchOpen ? " show" : "")}
              name="name"
              placeholder={t("sportObjectsSearch.inputSearch")}
              autoComplete="off"
              value={searchText}
              onChange={changeSearch}
              onReset={() => setSearchOpen(false)}
            />

            <div
              className={
                "filter-block" +
                (isFilterOpen ? " filter-open" : "") +
                (isSearchOpen ? " search-open" : "")
              }
            >
              <span></span>
              <span>
                <b>{t("sportObjectsSearch.stets.step-1")}</b>{" "}
                {t("sportObjectsSearch.stets.stepName-1")}
              </span>
              <span>
                <b>{t("sportObjectsSearch.stets.step-2")}</b>{" "}
                {t("sportObjectsSearch.stets.stepName-2")}
              </span>
              <span>
                <b>{t("sportObjectsSearch.stets.step-3")}</b>{" "}
                {t("sportObjectsSearch.stets.stepName-3")}{" "}
              </span>
              <span></span>
              <span></span>
              <button
                className="close"
                onClick={() => setFilterOpen(!isFilterOpen)}
              >
                &#9932;
              </button>
              <div className="title">Фильтр</div>
              <button
                className="search-dropdown"
                onClick={() => setSearchOpen(true)}
              >
                &nbsp;
              </button>
              <InputSearch
                className="search"
                name="name"
                autoComplete="off"
                value={searchText}
                placeholder={t("sportObjectsSearch.inputSearch")}
                onChange={changeSearch}
              />
              <Select
                values={[selectedFilter.subscription]}
                placeholder={t("sportObjectsSearch.selects.level")}
                options={loadedFilters.subscription}
                onUpdate={setSubscriptionOpenCity}
              />
              <CityRegionSelect
                values={[selectedFilter.city]}
                subscription={selectedFilter.subscription}
                placeholder={t("sportObjectsSearch.selects.city")}
                options={loadedFilters.city}
                defaults={loadedFilters.defaults}
                onSetMapPosition={setSelectedCity}
                onUpdate={() => {}}
                isCityMenuOpen={isCityMenuOpen}
                setCityMenuOpen={setCityMenuOpen}
              />
              <Select
                values={selectedFilter.activity}
                placeholder={t("sportObjectsSearch.selects.activity")}
                options={loadedFilters.activity}
                multiselect
                onUpdate={setActivities}
              />
              <Select
                values={selectedFilter.tag}
                placeholder={t("sportObjectsSearch.selects.tag")}
                options={loadedFilters.tag}
                multiselect
                onUpdate={setTags}
              />

              <button
                className="reset"
                title={t("sportObjectsSearch.buttonTitle")}
                onClick={clearFilter}
              >
                {t("sportObjectsSearch.buttonTitle")}
              </button>
              <Button color="accept" onClick={() => setFilterOpen(false)}>
                {t("sportObjectsSearch.buttonAccept", { number: searchableSuppliers.length })}
              </Button>
            </div>
          </form>
          <div className="objects">
            <div className="selected-items">
              <div key="subscription">
                {translate("subscription", selectedFilter.subscription)}
              </div>
              <div key="city">{translate("city", selectedFilter.city)}</div>
              {selectedFilter.tag.map((item) => {
                return (
                  <div key={"tag_" + item}>
                    {translate("tag", item)}{" "}
                    <button onClick={() => removeByValue(item, "tag")}>x</button>
                  </div>
                )
              })}
              {selectedFilter.activity.map((item) => {
                return (
                  <div key={"activity_" + item}>
                    {translate("activity", item)}{" "}
                    <button onClick={() => removeByValue(item, "activity")}>
                      x
                    </button>
                  </div>
                )
              })}
            </div>

            <div
              className={classNames("search-results", {
                visible: isSearchOpen || !!activeSupplier,
              })}
              ref={parentListRef}
            >
              {searchableSuppliers.map((supplier) => {
                return (
                  <button
                    className={"result" + (isSearchOpen ? " always" : "")}
                    key={supplier.id}
                    onClick={() => buttonSetActiveSupplier(supplier)}
                  >
                    {supplier.name}
                  </button>
                )
              })}
            </div>
            <MapComponent
              mapCenterPointAndZoom={mapCenterPointAndZoom}
              onLoad={() =>
                doLoadMapFilters(emptyFilter).then(() =>
                  loadingMapAndList(selectedFilter, searchText)
                )
              }
              hideActiveFeature={hideActiveSupplier}
              activeSupplier={activeSupplier}
              markers={loadedMarkers}
              setActiveSupplier={(supplier) => doLoadSupplier(supplier).then(addHistoryStateSupplierId)}
              setMoveEnd={setMoveEnd}
              setZoomEnd={setZoomEnd}
              fixCenterCauseYandex={fixCenterCauseYandex}
            />
          </div>
        </>
        : null
      }
      <SupplierDetails
        ref={modalRef}
        activeSupplier={activeSupplier}
        emptyMapFilters={emptyMapFilters}
        setActiveSupplier={setActiveSupplier}
        setLoadedFilters={setLoadedFilters}
      />
      <div className="map-footer">{t("sportObjectsSearch.mapFooter")}</div>
      {!isEmpty(activeSupplier) && (
          <ObjectInfo
            key={activeSupplier.id}
            close={hideActiveSupplier}
            supplier={activeSupplier}
            loadedFilters={loadedFilters}
            subscription={selectedFilter.subscription}
          />
        )}
    </section>
  )
}

export default SportObjectsSearch
