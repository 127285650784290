import React, { useRef, useState } from "react"
import classNames from "classnames"

import "./Select.scss"
import Checkbox from "../Checkbox/Checkbox"
import { useOutsideCatch } from "../../../hooks/useOutsideCatch"
import Button from "../../../common/Button/Button"
import { useTranslation } from "react-i18next"

function Select({
  values,
  options,
  color,
  multiselect,
  placeholder,
  onUpdate,
}) {
  const { t } = useTranslation()
  const [isMenuOpen, setMenuOpen] = useState(false)

  function addLabel(value) {
    onUpdate(values.concat(value))
  }
  function deleteLabel(value) {
    onUpdate(values.filter(item => value !== item))
  }
  function replaceLabel(value) {
    onUpdate(value)
    setMenuOpen(false)
  }
  function handleUpdate() {
    setMenuOpen(false)
  }
  function closeIfBackEvent(e) {
    if ([8, 46].indexOf(e.key) !== -1) {
      setMenuOpen(false)
    }
  }
  function handleCleanup(event) {
    event.preventDefault()
    event.stopPropagation()
    return onUpdate([])
  }
  function renderOptions() {
    return (
      <div className="options">
        <div
          className="back"
          onKeyUp={e => closeIfBackEvent(e)}
          onClick={() => setMenuOpen(false)}
          role="button"
          tabIndex={0}
        >
          {t('cityRegionSelect.open_filters')}
          <button className="close" onClick={() => setMenuOpen(false)} aria-label="close" />
        </div>
        {options.map((item,i) => {
          return multiselect ? (
            <label key={item.key} htmlFor={`item${i}`}>
              <Checkbox
                item={item}
                checked={values.indexOf(item.key) !== -1}
                onAdd={addLabel}
                onDelete={deleteLabel}
              />
            </label>
          ) : (
            <label key={item.key} htmlFor={`item${i}`}>
              <Checkbox
                item={item}
                checked={values.indexOf(item.key) !== -1}
                onAdd={replaceLabel}
                onDelete={() => {}}
                type="radio"
              />
            </label>
          )
        })}
        <Button color="accept" onClick={handleUpdate}>
          {t('cityRegionSelect.accept')}
        </Button>
      </div>
    )
  }
  const wrapperRef = useRef(null)
  useOutsideCatch(wrapperRef, () => {
    setMenuOpen(false)
  })
  function handleOpenMenu(event) {
    event.preventDefault()
    event.stopPropagation()
    setMenuOpen(!isMenuOpen)
  }
  return (
    <div
      ref={wrapperRef}
      className={classNames("sport-object-search-select", color, {
        multiselect: multiselect,
        "menu-open": isMenuOpen,
      })}
    >
      <button
        onClick={handleOpenMenu}
        className={classNames("", "", {
          empty: !values.length,
        })}
      >
        {placeholder}
        <span className="cleanup" onClick={handleCleanup}
          onChange={()=>{}}
          aria-hidden="true">
          {t('cityRegionSelect.reset')}
        </span>
      </button>

      {isMenuOpen && renderOptions()}
    </div>
  )
}

export default Select
