import React from "react"
import classNames from "classnames"

import "./InputSearch.scss"

function InputSearch({
  defaultType,
  value,
  onChange,
  name,
  placeholder,
  className,
  onReset,
  ...rest
}) {
  function handleChange(event) {
    const { value } = event.target

    onChange(value, name)
  }
  function handleReset() {
    if (onReset) {
      onReset()
    }
    onChange("", name)
  }
  const type = defaultType ? defaultType : "input"
  return (
    <div className={classNames("sport-object-input-search", className)}>
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        {...rest}
      />
      <div onClick={handleReset} onChange={()=>{}} aria-hidden="true" className="reset">
        &nbsp;
      </div>
    </div>
  )
}

export default InputSearch
