import {
  forwardRef,
  useImperativeHandle,
} from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"


const SupplierDetailsModal = forwardRef(
  (
    {
      emptyMapFilters,
      setActiveSupplier,
      setLoadedFilters,
    },
    ref
  ) => {
    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            serverPath
          }
        }
      }
    `)
    const { pathname } = useLocation()
    useImperativeHandle(ref, () => ({
      doLoadMapFilters: (filters) => doLoadMapFilters(filters),
      doLoadSupplier: (supplier) => doLoadSupplier(supplier),
    }))
    const convertInputDictionaryToObjects = (dictionary) => {
      const result = {}
      let item = null
      for (let key in dictionary) {
        item = dictionary[key]
        result[item.key] = item.value
      }
      return result
    }
    const doLoadMapFilters = (filters) => {
      const query = `
        {
  map_filters(general: {language: "${filters.language}", city: "${filters.city}", subscription: "${filters.subscription}", country: "${filters.country}"}) {
    subscription {
      key
      value
    }
    city {
      key
      value
    }
    tag {
      key
      value
    }
    activity {
      key
      value
    }
    zoom
    location {
      lat
      lng
    }
    defaults {
      subscription
      city
    }
  }
}
    `

      return fetch(data.site.siteMetadata.serverPath, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response && response.data && response.data.map_filters) {
            const mapFilters = response.data.map_filters

            mapFilters.dictionary = {
              subscription: convertInputDictionaryToObjects(
                mapFilters.subscription
              ),
              city: convertInputDictionaryToObjects(mapFilters.city),
              tag: convertInputDictionaryToObjects(mapFilters.tag),
              activity: convertInputDictionaryToObjects(mapFilters.activity),
            }
            setLoadedFilters(mapFilters)
            return mapFilters
          }
        })
        .catch((e) => {
          console.error(e)
          setLoadedFilters(emptyMapFilters)
        })
    }
    const doLoadSupplier = (supplier) => {
      // TOOD: add condition_list to JOURNAL/SEARCH/HERE
      const query = `
        {
  supplier(id:${supplier.id}) {
    id
    name
    info
    rules
    gallery
    district
    condition_list {
      key
      value
    }
    phone
    url
    address
    lat
    lng
    tags
    mon
    tue
    wed
    thu
    fri
    sat
    sun
    last_changed_at
    sh_message
    sh_url
    attraction_objects {
        id
        list
        has_copayment
        copayment
        levels
        subscriptions
    }
    created_at
    updated_at
  }
}
    `
      return fetch(data.site.siteMetadata.serverPath, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response && response.data && response.data.supplier) {
            const supplier = response.data.supplier
            supplier.condition_list = convertInputDictionaryToObjects(
              supplier.condition_list
            )

            setActiveSupplier(supplier)
            if (window && window.location && !window.location.search) {
              try {
                window.history.pushState(
                  { supplier_id: supplier.id },
                  `Объект ${supplier.name}`,
                  `${pathname}?supplier_id=${supplier.id}&supplier_name=${supplier.name}`
                )
              } catch (e) {
                console.log(e)
              }
            }
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
    
    return null
  }
)

export default SupplierDetailsModal
